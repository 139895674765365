import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPredictions } from "../../utils/api";

export const All = () => {
  const navigate = useNavigate();
  const [predictions, setPredictions] = useState([]);
  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        const predictions = await getPredictions();
        setPredictions(predictions);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPredictions();
  }, []);

  const onClick = (id) => {
    navigate('/singlePrediction?id=' + id);
  };

  const renderPredictions = () => {
    return predictions.map((prediction) => (
      <tr onClick={() => onClick(prediction.id)} key={prediction.id}>
        <td>{prediction.predictionImgUrl}</td>
        <td>{prediction.rating}</td>
      </tr>
    )
    );
  };

  return (
    <div>
      <table>
        <tbody>
          {renderPredictions()}
        </tbody>
      </table>
    </div>
  );
}
