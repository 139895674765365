import './index.css';
import { Square } from './square';

export const Board = ({ squareColors, onClick }) => (
  <div className="board">
    <div className="board-row">
      <Square onClick={() => onClick(1)} color={squareColors[1]}/>
      <Square onClick={() => onClick(2)} color={squareColors[2]}/>
      <Square onClick={() => onClick(3)} color={squareColors[3]}/>
    </div>
    <div className="board-row">
      <Square onClick={() => onClick(4)} color={squareColors[4]}/>
      <Square onClick={() => onClick(5)} color={squareColors[5]}/>
      <Square onClick={() => onClick(6)} color={squareColors[6]}/>
    </div>
    <div className="board-row">
      <Square onClick={() => onClick(7)} color={squareColors[7]}/>
      <Square onClick={() => onClick(8)} color={squareColors[8]}/>
      <Square onClick={() => onClick(9)} color={squareColors[9]}/>
    </div>
  </div>
);
