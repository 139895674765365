const baseUrl = {
  local: 'http://localhost:3001',
};
const routes = {
  image: 'image',
  predict: 'predict',
  predictions: 'predictions',
  prediction: 'prediction',
};

const sendGetRequest = async (url, queryParams) => {
  let fullRoute = `${baseUrl.local}/${url}`;
  if (queryParams) fullRoute += '?' + new URLSearchParams(queryParams);
  const res = await fetch(fullRoute);
  const resBody = await res.json();
  if (!res.ok) throw resBody;
  return resBody; 
};

const sendPostRequest = async (url, body) => {
  const res = await fetch(`${baseUrl.local}/${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  const resBody = await res.json();
  if (!res.ok) throw resBody;
  return resBody;
};

export const getImageUrl = async () => {
  const randomWidth = Math.floor(Math.random() * 75) * 10 + 250;
  const randomHeight = Math.floor(Math.random() * 75) * 10 + 250;
  return fetch(`https://picsum.photos/${randomWidth}/${randomHeight}`, {
    responseType: 'arraybuffer',
  });
};

export const predictImage = async (imageId) => {
  return sendPostRequest(routes.predict, { imageId });
};

export const savePrediction = async (prediction) => {
  sendPostRequest(routes.predictions, prediction);
};

export const getPredictions = async () => {
  return sendGetRequest(routes.predictions);
};

export const getSinglePrediction = async (id) => {
  return sendGetRequest(`${routes.prediction}`, {id});
};
