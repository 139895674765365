import { useEffect, useState } from 'react'
import { CounterContextConsumer } from '../../../../contexts/counterContext'
import { CounterBox } from '../../../../components/counterBox'
import './index.css'

export const Counter = () => {
  const { counter, round, isInteractive, message, setMessage } = CounterContextConsumer()

  useEffect(() => {
    if (counter === 3) setMessage('Probaility of guessing is 10%')
    if (counter === 4) setMessage('Maybe ESP, probaility of guessing is 3%')
    if (counter === 5) setMessage('ESP mostly certain, probaility of guessing is 0.5%')
    if (counter === 6) setMessage('ESP Confirmed, probaility of guessing is 0.08%')
    if (counter === 7) setMessage('ESP Rediculous, probaility of guessing is 0.008%')
    if (counter === 8) setMessage('ESP Emporer, probaility of guessing is 0.0007%')
    if (counter === 9) setMessage('Christ consciousness, probaility of guessing is 0.00002%')
    if (counter === 10) setMessage('ESP God, probaility of guessing is < 0.00002%')
  }, [counter])

  return (
    <div className='counter'>
      <div className='message-container'>
        <h6 className='message'>{message}</h6>
      </div>
      <div className='message-container'>
        {(isInteractive || round === 12) && <CounterBox content={`Correct ${counter}`} />}
        <CounterBox content={`Round ${round}`} />
      </div>
    </div>
  )
}
