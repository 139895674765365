import './index.css';
import { useNavigate } from "react-router-dom";
import { ImageConsumerContext } from "../../contexts/image";
import { Rating } from 'react-simple-star-rating'
import { useState } from 'react';
import { savePrediction } from '../../utils/api';
import { CounterBox } from '../../components/counterBox'
import Button from 'react-bootstrap/Button';

export const Results = () => {
  const [rating, setRating] = useState(0);

  const { predictionImgUrl, predictionNotes, drawingDataUrl } = ImageConsumerContext();

  const handleRating = (rate) => {
    setRating(rate)
  };

  const onPointerMove = (value, index) => console.log(value, index)

  const navigate = useNavigate();

  const saveImage = async () => {
    try {
      // savePrediction({ predictionImgUrl, rating, predictionNotes, drawingDataUrl });
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='image-container'>
      <img src={predictionImgUrl} className='result-image' alt="random-prescient-image"/>
      <img src={drawingDataUrl} className='result-image' alt="random-prescient-image"/>
      <div className='notes-container'>
        <h3>Notes:</h3>
        <p>{predictionNotes}</p>
      </div>
      {/* <Rating */}
      {/*   onClick={handleRating} */}
      {/*   ratingValue={rating} */}
      {/*   size={20} */}
      {/*   label */}
      {/*   transition */}
      {/*   fillColor='orange' */}
      {/*   emptyColor='gray' */}
      {/*   className='margin-top' */}
      {/*   onPointerMove={onPointerMove} */}
      {/*   allowFraction */}
      {/* /> */}
      <div className='button-container'>
        <Button onClick={() => navigate('/')}>Back</Button>
      </div>
    </div>
  );
};
