import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom";

export const Navigation = ({ children }) => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        xs="auto"
        sm="auto"
        lg="auto"
        xl="auto"
        className="bg-body-tertiary"
        data-bs-theme="dark"
      >
        <Container>
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate('/')}>RV</Nav.Link>
            <Nav.Link onClick={() => navigate('/esp-square')}>9square</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      {children}
    </>
  );
  // return (
  //   <div>
  //     <p style={{display: 'inline-block', paddingLeft: '10px'}} ><a onClick={() => onNavigate("/")}>Home</a></p>
  //     <p style={{display: 'inline-block', paddingLeft: '10px'}}><a onClick={() => onNavigate("/all")}>All</a></p>
  //     <p style={{display: 'inline-block', paddingLeft: '10px'}}><a onClick={() => onNavigate('/esp-square')}>9Square</a></p>
  //     {children}
  //   </div>
  // );
};
