export const tryCount = 12;

export const psiSquareColors = {
  1: '#538fff',
  2: '#b431e6',
  3: '#f7ed65',
  4: '#28d2ab',
  5: '#fca207',
  6: '#f6ccf9',
  7: 'cyan',
  8: '#268189',
  9: '#2d1a77',
}

export const correctChoiceColor = 'green';
export const wrongChoiceColor = 'red';
export const passChoiceColor = 'blue';
