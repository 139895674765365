import { createContext, useContext, useMemo } from "react";
import { useState } from 'react'
import { tryCount } from "../utils/consts";

const CounterContext = createContext({})

export const CounterProvider = ({ children }) => {
  const [counter, setCounter] = useState(0);
  const [round, setRound] = useState(0);
  const [isInteractive, setIsInteractive] = useState(true);
  const [message, setMessage] = useState('')

  const increaseCounter = () => {
    if (round >= tryCount) {
      setRound(0)
      setCounter(0)
      return;
    }
    setCounter(counter + 1)
  };
  
  const finalParams = useMemo(() => {
    return {
      increaseCounter,
      counter,
      setCounter,
      round,
      setRound,
      isInteractive,
      setIsInteractive,
      message,
      setMessage
    }
  }, [counter, round, isInteractive, message])

  return <CounterContext.Provider value={finalParams}>{children}</CounterContext.Provider>
};

export const CounterContextConsumer = () => useContext(CounterContext)
