import './index.css';
import { useLocation } from "react-router-dom";
import { Rating } from 'react-simple-star-rating'
import { useEffect, useState } from 'react';
import { getSinglePrediction } from '../../utils/api';

export const SinglePrediction = () => {
  const location = useLocation()
  const id = new URLSearchParams(location.search).get('id');
  const [rating, setRating] = useState(0);
  const [predictionNotes, setPredictionNotes] = useState('');
  const [predictionImgUrl, setPredictionImgUrl] = useState('');
  const [drawingDataUrl, setDrawingDataUrl] = useState('');

  useEffect(() => {
    const getPrediction = async () => {
      const prediction = await getSinglePrediction(id);
      setPredictionNotes(prediction.predictionNotes);
      setPredictionImgUrl(prediction.predictionImgUrl);
      setDrawingDataUrl(prediction.drawingDataUrl);
      setRating(prediction.rating);
    }
    getPrediction();
  }, []);

  return (
    <div className='image-container margin-top'>
      <h2>Rating: {rating}</h2>
      <img src={predictionImgUrl} className='margin-top' alt="random-prescient-image"/>
      <p>{predictionNotes}</p>
      <img src={drawingDataUrl} className='margin-top' alt="random-prescient-image"/>
      {/* <Rating */}
      {/*   ratingValue={rating} */}
      {/*   size={20} */}
      {/*   label */}
      {/*   transition */}
      {/*   fillColor='orange' */}
      {/*   emptyColor='gray' */}
      {/*   className='margin-top' */}
      {/*   allowFraction */}
      {/* /> */}
    </div>
  );
};
