import { Board } from "./board"
import { Counter } from "./board/counter";
import { BoardControls } from "./board/boardControls";
import { useState } from "react";
import { CounterContextConsumer } from "../../contexts/counterContext";
import { correctChoiceColor, psiSquareColors, wrongChoiceColor } from "../../utils/consts";

export const PsySquare = () => {
  const [squareColors, setSquareColors] = useState(psiSquareColors);

  const { setCounter, round, setRound, isInteractive } = CounterContextConsumer();

  const onSquareClick = async (value) => {
    if (round >= 12) return;

    const correctCoice = Math.floor(Math.random() * 9) + 1;
    const isCorrect = correctCoice === value;
    if (isCorrect) setCounter((prev) => prev + 1);

    setRound(round + 1);

    if (!isInteractive) return;

    setSquareColors(prev => {
      if (isCorrect) return { ...prev, [value]: correctChoiceColor }
      return {
        ...prev,
        [value]: wrongChoiceColor,
        [correctCoice]: correctChoiceColor,
      }
    });

    setTimeout(() => setSquareColors(psiSquareColors), 300);
  };

  const onPass = () => {
    const correctCoice = Math.floor(Math.random() * 9) + 1;
    setSquareColors(prev => ({ ...prev, [correctCoice]: correctChoiceColor }));
    setTimeout(() => setSquareColors(psiSquareColors), 300);
  };

  return (
    <>
      <Counter />
      <BoardControls onPass={onPass}/>
      <Board squareColors={squareColors} onClick={onSquareClick}/>
    </>
  )
};
