import "./index.css";
import { useEffect, useRef, useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { useNavigate } from "react-router-dom";
import { ImageConsumerContext } from "../../contexts/image";
import Button from "react-bootstrap/Button";
import { CustomModal } from "../../components/modal";

export const Prescience = () => {
  const {
    predictionImgUrl,
    imageId,
    retriveNewImageUrl,
    predictionNotes,
    setPredictionNotes,
    setPrescientDrawing,
  } = ImageConsumerContext();
  const [isEraseMode, setIsEraseMode] = useState(false);

  const canvasRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    setPredictionNotes("");
    retriveNewImageUrl();

    const handleKeyup = ({ repeat, ctrlKey, key }) => {
      if (repeat) return

      if (ctrlKey && key === 'U') canvasRef.current.undo();
      if (ctrlKey && key === 'L') canvasRef.current.redo();
    };
    window.addEventListener("keyup", handleKeyup);

    return () => {
      return window.removeEventListener("keyup", handleKeyup);
    }
  }, []);

  const handleSubmitClick = async () => {
    const drawing = await canvasRef.current.exportImage('png');
    setPrescientDrawing(drawing);
    navigate('/results');
  };

  const onEraseMode = () => {
    canvasRef.current.eraseMode(!isEraseMode);
    setIsEraseMode(!isEraseMode);
  };

  return (
    <div className="main-body">
      <div className={"prescience-container"}>
        { imageId ? ( <h2>{imageId}</h2> ) : <h2>Loading...</h2> }
        <div className="image-container">
          <ReactSketchCanvas 
            strokeWidth={4}
            width="90vw"
            strokeColor="black"
            ref={canvasRef}
          />
        </div>
        <div className="button-container">
          <CustomModal title="RV Notes" buttonLabel="RV Notes">
            <textarea
              type="text"
              className="text-area"
              placeholder="Enter your prediction notes here..."
              onChange={(e) => setPredictionNotes(e.target.value)}
              value={predictionNotes}
            />
          </CustomModal>
        </div>
      </div>
      <div className="prediction-image" hidden={true}>
        <img src={predictionImgUrl} alt="random-prescient-image"/>
      </div>
      <div className="button-container">
        <Button variant="danger" className="button" onClick={() => canvasRef.current.clearCanvas()}>Clear</Button>
        <Button variant={isEraseMode ? "success" : "info"} className="button" onClick={onEraseMode}>{isEraseMode ? "Erase: on" : "Erase: off"}</Button>
        <Button variant="dark" className="button" onClick={handleSubmitClick}>Submit</Button>
      </div>
    </div>
  );
};
