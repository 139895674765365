import './index.css'
import { Button } from 'react-bootstrap'
import { CounterContextConsumer } from '../../../../contexts/counterContext';

export const BoardControls = ({ onPass }) => {
  const {
    isInteractive,
    setIsInteractive,
    setRound,
    setCounter,
    setMessage,
  } = CounterContextConsumer();

  const onReset = async () => {
    setRound(0)
    setCounter(0)
    setMessage('')
  };

  return (
    <div className='button-container'>
      <Button className='margin-right' variant="danger" onClick={onReset}>Reset</Button>
      <Button className='margin-right' variant={isInteractive ? 'success' : 'warning'} onClick={() => setIsInteractive(prev => !prev)}>Interactive {isInteractive ? 'On' : 'Off'}</Button>
      <Button variant="info" onClick={onPass}>Pass</Button>
    </div>
  )
};
