import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Prescience } from './routes/prescience';
import { ImageProvider } from './contexts/image';
import { Results } from './routes/results';
import { All } from './routes/all';
import { SinglePrediction } from './routes/singlePrediction';
import { Navigation } from './components/navigation';
import { CounterProvider } from './contexts/counterContext';
import { PsySquare } from './routes/psiSquare';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <ImageProvider>
            <Navigation>
              <Prescience />
            </Navigation>
          </ImageProvider>
        } 
        />

        <Route path="/results" element={
          <ImageProvider>
            <Navigation>
              <Results />
            </Navigation>
          </ImageProvider>
        } 
        />

        <Route path="/all" element={
          <ImageProvider>
            <Navigation>
              <All />
            </Navigation>
          </ImageProvider>
        } 
        />

        <Route path="/singlePrediction" element={
          <ImageProvider>
            <Navigation>
              <SinglePrediction />
            </Navigation>
          </ImageProvider>
        } 
        />

        <Route path="/esp-square" element={
          <CounterProvider>
            <Navigation>
              <PsySquare />
            </Navigation>
          </CounterProvider>
        } />
      </Routes>
    </Router>
  );
}

export default App;
