import { createContext, useContext, useMemo, useState } from "react";
import { getImageUrl } from "../utils/api/index.js";

const ImageContext = createContext({});

export const ImageProvider = ({ children }) => {
  const [predictionImgUrl, setImageUrl] = useState("");
  const [imageId, setImageId] = useState(0);
  const [drawingDataUrl, setPrescientDrawing] = useState({});
  const [predictionNotes, setPredictionNotes] = useState("");

  const retriveNewImageUrl = async () => {
    try {
      const { url: responseUrl } = await getImageUrl();
      setImageId(responseUrl);
      const imageId = responseUrl.split("=")[1];
      setImageId(imageId.slice(0, 12));
      setImageUrl(responseUrl);
    } catch (error) {
      console.log(error);
    }
  }

  const finalParams = useMemo(() => ({
    predictionImgUrl,
    imageId,
    retriveNewImageUrl,
    predictionNotes,
    setPredictionNotes,
    drawingDataUrl,
    setPrescientDrawing,
  }), [predictionImgUrl, predictionNotes, drawingDataUrl, imageId]);

  return (
    <ImageContext.Provider value={finalParams}>
      {children}
    </ImageContext.Provider>
  );
};

export const ImageConsumerContext = () => useContext(ImageContext);
